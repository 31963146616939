$(function () {

    if ($('.resources-module') != null) {

        const url = `${window.location.origin}/umbraco/surface/json/IpToCountryCode`;

        if (!getCookie('countryCode')) {

            console.debug('Fetching live country code.');

            fetch(url)
                .then(res => res.json())
                .then(data => {
                    if (data != null) {

                        if (data.countryCode == 'uk') {

                            $('.resources-module').each(function () {
                                $(this).find(`ul.acc li[data-region="UK"]`).addClass('visible');
                                $(this).find(`ul.acc li[data-region="US"]`).remove();
                            });

                        } else {

                            $('.resources-module').each(function () {
                                $(this).find(`ul.acc li[data-region="US"]`).addClass('visible');
                                $(this).find(`ul.acc li[data-region="UK"]`).remove();
                            })
                        }

                        setCookie('countryCode', data.countryCode, 7);

                    }


                }).catch(err => {
                    console.error('Error occurred');
                    $(this).find(`ul.acc li[data-region="UK"]`).addClass('visible');
                    $(this).find(`ul.acc li[data-region="US"]`).remove();
                })
        } else {
            console.debug('Fetching country code from cache');

            const countryCode = getCookie('countryCode');

        }

    }

})