$(function () {

    const downloadBox = $('.download-box');

    $('.resources-module').each(function (el) {
        $(this).find('ul.acc li.download > a').on('click', function (el) {

            //open download popup
            const $li = $(this).parents('li');
            const $clonedLi = $('<li class="download-popup visible"></li>').append(downloadBox);

            //If clicking box that is already open, then close and remove li
            if ($li.hasClass('download-form-open')) {

                $li.siblings('li.download-popup').remove();
                downloadBox.removeClass('visible');
                $li.removeClass('download-form-open');

            } else {                

                //Check if box already opened elsewhere, if so remove the li item
                if ($('ul.acc li.download-popup').length) {

                    $('ul.acc li.download-popup').remove();                                        
                    $('ul.acc li.download.download-form-open').removeClass('download-form-open')

                }

                $li.addClass('download-form-open');

                $clonedLi.insertAfter($li);
                downloadBox.addClass('visible');
            }            
        });

        $('.close-download').on('click', function() {
            $(this).parents('li.download-popup').remove();
            $('.resources-module ul.acc li.download-form-open').removeClass('download-form-open');
        })
    })

})