window.addEventListener('load', () => {
    $('.home-banner .background-image img').one('load', () => {

    }).each(function() {
        if (this.complete && !$(this).parent('.background-image').hasClass('loaded')) {
    
            const $this = $(this);
        
            setTimeout(function() {
                $this.parent('.background-image').addClass('loaded')
            }, 50)
        }
    });

    //Sub page banners
    $('.inner-banner > figure img').one('load', () => {

    }).each(function() {
        if (this.complete && !$(this).parent('figure').hasClass('loaded')) {
    
            const $this = $(this);
    
            setTimeout(function() {
                $this.parent('figure').addClass('loaded')
            }, 50)
        }
    })
})