$(function () {
    // Tabs for Feed
    if($('.tabs nav li').length > 0){
        $('.tabs nav li').click(function () {
            var tabIndex = $(this).index();

            if ($('.tabs ul.tabs li.tab.active').find('.video-block video').length) {                
                
                if (!$('.tabs ul.tabs li.tab.active').find('.video-block video')[0].paused) {
                    $('.tabs ul.tabs li.tab.active').find('.video-block video')[0].pause();
                }                
            }

            $('.tabs nav li').removeClass('active');
            $(this).addClass('active');

            $('.tabs ul.tabs li.tab').removeClass('active');
            $('.tabs ul.tabs li.tab').eq(tabIndex).addClass('active');
            
        });  
    };    
});
