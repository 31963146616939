$(function() {

    const MULTIPLIER = 4;

    const checkMore = (items) => {
        return items > 0 ? true : false;
    }

    //load more handler
    $('.resources-module').each(function() {

        const $parent = $(this);

        let COUNT = 1;

        const items = $(this).find('ul.acc > li');

        //If there are more than the multiplier (4), we can load more
        if (checkMore(items.length)) {
            $(this).find('.loadmore').on('click', function() {                

                for (let i = (MULTIPLIER * COUNT); i < (MULTIPLIER * (COUNT + 1)); i++) {                    
                    items.eq(i).addClass('visible');
                }

                COUNT = COUNT + 1;

                if (!checkMore($parent.find('ul.acc > li:not(.visible)').length)) {
                    $(this).remove();
                }

            })
        }

    });
    
})