let checkViewportSlider = function(el) {
    if (window.innerWidth >= 992) {
        if (el.length >= 4) {
            return true;
        }

        return false;
    }

    return true;
}

window.addEventListener('load', () => {
    // Testimonial Slider
    if ($('.testimonial-slideSet').length > 0) {

        let canDrag = checkViewportSlider($('.testimonial-slideSet .block'));

        const options = {
            cellAlign: 'center',
            wrapAround: false,
            contain: true,
            pageDots: false,
            groupCells: false,
            prevNextButtons: false,
            draggable: canDrag
        }

        $carousel =$('.testimonial-slideSet').flickity(options);

        $(window).on('resize', function() {
            
            if (canDrag != checkViewportSlider($('.testimonial-slideSet .block'))) {

                canDrag = checkViewportSlider($('.testimonial-slideSet .block'));
                options.draggable = canDrag;

                $('.testimonial-slideSet').flickity('destroy');
                $('.testimonial-slideSet').flickity(options);
            } 

            
        })

    }

    // $('.testimonial-slideSet').on('click', function () {
    //     $carousel.flickity('resize');
    // });

    // Logo Slider
    if ($('.logoSlider').length > 0) {

        let partnerLogos = false;

        if ($('.logoSlider').parents('.logo-module').hasClass('with-partner-logo')) {
            partnerLogos = true;
        }

        $('.logoSlider').flickity({
            cellAlign: $(this).find('.logo-item').length < 4 && partnerLogos ? 'left' : 'center',
            wrapAround: false,
            contain: true,
            pageDots: false,
            prevNextButtons: false,
        });
    }
    $('.logoSlider').on('click', function () {
        $carousel.toggleClass('flickity-slider').flickity('resize');
    });

    // How it Work Slider
    if ($('#howitworkSlider').length > 0) {
        $('#howitworkSlider').flickity({
            cellAlign: 'center',
            wrapAround: true,
            groupCells: 1,
            freeScroll: true,
            contain: true,
            pageDots: false,
            prevNextButtons: true,            
        });

        $('#howitworkSlider .slider').on('click', function() {
            const index = $(this).index();
            $('#howitworkSlider').flickity('select', index)
        })
    };

    //Banner slider
    if ($('.banner-slider').length) {
        $('.banner-slider').each(function() {

            let $this = $(this);
            let $bannerSlider;

            const progressBar = $(this).find('.slider-progress-bar-grid').find('.slider-progress-bar');            

            $bannerSlider = $(this).find('.banner-slider__track > div').flickity({
                contain: false,
                pageDots: false,
                prevNextButtons: false,
                cellAlign: 'left',
                adaptiveHeight: false,
                autoPlay: 5000,
                wrapAround: true,
                pauseAutoPlayOnHover: false,
                on : {
                    ready: () => {
                        $(this).addClass('ready')
                    }
                }
            });

            $bannerSlider.on('change.flickity', function (evt, index) {
                progress = Math.max(0, Math.min(1, index));
                progressBar.css('left', (index * 50) + '%');
                
                $this.find('.banner-slider__progress ul > li.active').removeClass('active');
                $this.find('.banner-slider__progress ul > li').eq(index).addClass('active');
            });

            $(window).on('resize', function() {
                $bannerSlider.flickity('resize');
            });

        })
    }

    // Group Slider
    if ($('.groupSlider').length > 0) {
        $('.groupSlider').each(function() {

            let $groupSlider;
            const progressBar = $(this).next('.slider-progress-bar-grid').find('.slider-progress-bar');            

            $groupSlider = $(this).flickity({
                cellAlign: 'left',
                wrapAround: false,
                groupCells: 1,
                freeScroll: true,
                contain: true,
                pageDots: false,
                prevNextButtons: true 
            });                    

            $groupSlider.on('scroll.flickity', function (evt, progress) {
                progress = Math.max(0, Math.min(1, progress));
                progressBar.width(progress * 100 + '%');
            });

        });
    };
})