$(function () {

    $('.video-block').each(function() {

        if ($(this).find('video').length) {

            const control = $(this).find('.play-icon');            
            const videoEl = $(this).find('video')[0];

            control.on('click', () => {
                console.log(videoEl)

                if (videoEl.paused) {
                    
                    videoEl.play();
                    videoEl.setAttribute('controls', true);
                    control.hide();

                } else {

                    videoEl.pause();
                    videoEl.setAttribute('controls', false);
                    control.show();
                }

            });

            videoEl.addEventListener('pause', () => {
                videoEl.setAttribute('controls', false);
                control.show();
            })

            videoEl.addEventListener('play', () => {
                videoEl.setAttribute('controls', true);
                control.hide();
            })
        }

    });

    $('.trigger-video').on('click', function() {
        const videoUrl = $(this).data('video');
        
        const sitePopup = $('.site-popup.team-popup');

        if (videoUrl == 0) {
            sitePopup.find('video').hide();
        } else {
            sitePopup.find('iframe').hide();
        }

        //dont replace if clicking same video
        if (sitePopup.find('video').attr('src') != videoUrl) {             
            sitePopup.find('video').attr('src', videoUrl);
        }
 
        sitePopup.addClass('open');

        $('body').addClass('popup-open');

        //play video
        if (sitePopup.find('video') != null) {
            sitePopup.find('video')[0].play();
        }

    });

    $('.site-popup').find('.close-popup').on('click', function() {
        const parent = $(this).parents('.site-popup');

        parent.removeClass('open');
        $('body').removeClass('popup-open');

        //if popup contains video, pause it
        if (parent.find('video') != null) {
            parent.find('video')[0].pause();
        }
    })

    $('.site-popup__inner').fitVids();
    $('.inline-video-wrapper').fitVids();

})